import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject,Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessHTTPMsgService } from '../auth/process-httpmsg.service';


@Injectable({
  providedIn: 'root'
})
export class ComplaintsService {
  postSuccess = new Subject()
  constructor(
    @Inject('baseURL') private  baseURL,
    private http: HttpClient,
    private processHTTPMsgService: ProcessHTTPMsgService) {
  }

  getResolvedComplaints(): Observable<any[]> {
    return this.http.get<any[]>(this.baseURL + 'resolve')
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  updateResolvedInMaster(id){
   return this.http.put(this.baseURL + 'resolve/'+ id,{recent:false})
    .pipe(catchError(this.processHTTPMsgService.handleError))
  }
  postComplaint(complaint: any) {
    return this.http.post(this.baseURL + 'complaint', complaint)
      .pipe(catchError(this.processHTTPMsgService.handleError)).toPromise()
  }

  findComplaintOne(order){
    return this.http.get<any[]>(this.baseURL + 'complaint?order_no='+order)
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  deleteComplaint(id){
    this.http.delete(this.baseURL + 'complaint/'+ id)
      .pipe(catchError(this.processHTTPMsgService.handleError)).subscribe(res=>{
        console.log('delete success', res);
      })
  }
 
  getAllComplaints(): Observable<any[]> {
    return this.http.get<any[]>(this.baseURL + 'complaint')
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  getTodayComplaints(){
    return this.http.get<any[]>(this.baseURL + 'complaint?recent=' + true)
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  getPendingCount(colName){
    return this.http.get<any[]>(this.baseURL+"pending/count/" + colName)
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  getNotReceived(){
    return this.http.get<any[]>(this.baseURL + 'pending?status=not_received')
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  findPendingOne(order_no,status){
    return this.http.get<any[]>(this.baseURL + `pending?order_no=${order_no}&=${status}`)
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  exportPendingByCrmidOrOrder(value,orderOrCr,status){
    return this.http.get<any[]>(this.baseURL + `pending?${orderOrCr}=${value}&status=${status}`)
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  exportComplaintByCrmidOrOrder(value,orderOrCr){
    return this.http.get<any[]>(this.baseURL + `complaint?${orderOrCr}=${value}`)
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  exportResolveByCrmidOrOrder(value,orderOrCr){
    return this.http.get<any[]>(this.baseURL + `complaint?${orderOrCr}=${value}`)
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  exportDateViseResolved(date){
    return this.http.post<any[]>(this.baseURL + 'resolve/' + `datewise`, date)
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  exportDateVisePending(date){
    return this.http.post<any[]>(this.baseURL + 'pending/' + `datewise`, date)
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  exportDateViseComplaint(date){
    return this.http.post<any[]>(this.baseURL + 'complaint/' + `datewise`, date)
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  exportResolved(){
      return this.http.get<any[]>(this.baseURL + 'resolve')
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  exportPending(status){
    return this.http.get<any[]>(this.baseURL + 'pending?status=' + status)
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  exportComplaint(){
    return this.http.get<any[]>(this.baseURL + 'complaint')
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  getUnMatch(){
    return this.http.get<any[]>(this.baseURL + 'pending?status=un_matched')
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getReceived(){
    return this.http.get<any[]>(this.baseURL + 'pending?status=received')
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }


  getTodayResolved(){
    return this.http.get<any[]>(this.baseURL + 'resolve?recent=' + true)
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }
getWeakWise(){
  return this.http.get<any[]>(this.baseURL + 'resolve/time/lastweek')
  .pipe(catchError(this.processHTTPMsgService.handleError));
}
getWeakWisePending(){
  return this.http.get<any[]>(this.baseURL + 'pending/time/lastweek')
  .pipe(catchError(this.processHTTPMsgService.handleError));
}
geLastMonthWise(){
  return this.http.get<any[]>(this.baseURL + 'resolve/time/month')
  .pipe(catchError(this.processHTTPMsgService.handleError));
}
  getAllResolved(){
    return this.http.get<any[]>(this.baseURL + 'resolve?recent=' + false )
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }
 
  getTotalCount(colName){
    return this.http.get<any[]>(this.baseURL+"count/" + colName)
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getDateVise(type,date){
    return this.http.post<any[]>(this.baseURL+type+"/datewise",date)
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  deleteComp(coll,id){
    this.http
    .delete(this.baseURL + `${coll}/` + id )
    .subscribe(res=>{
    })
  }

  getTechnicians(active){
    return this.http.get<any[]>(this.baseURL + 'profile?designation=Technician&active='+true)
    .pipe(catchError(this.processHTTPMsgService.handleError))
  }

  getWeakWiseResolved(){
    return this.http.get<any[]>(this.baseURL + 'resolve/time/lastweek')
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  getLastMonth(){
    var endDate = new Date();
    var  startDate = new Date();
    endDate.setDate(endDate.getDate() -30);
    var datePipe = new DatePipe("en-US");
    let sDate = datePipe.transform(startDate, 'MM/dd/yyyy');
    let eDate = datePipe.transform(endDate, 'MM/dd/yyyy');
    return this.http.post<any[]>(this.baseURL + 'resolve/' + `datewise` ,{
  
      "startDate": eDate,
      "endDate":sDate
      
    })
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getDateWiseTechnicians(date){
    return this.http.post<any[]>(this.baseURL + 'resolve/' + `datewise`, date)
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getDateVisePieAndBarChart(startDate,endDate){
    return this.http.get<any[]>(this.baseURL + `resolve/piechart?startDate=${startDate}&endDate=${endDate}`)
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getDateVisePieAndBarChartByDistAndCity(startDate,endDate,dist,city){
    return this.http.get<any[]>(this.baseURL + `resolve/piechart?startDate=${startDate}&endDate=${endDate}&distributor=${dist}&city_name=${city}`)
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getTechAllResolved(id){
    return this.http.get<any[]>(this.baseURL  + 'resolve?technician='+id)
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getPendingByTech(status,id){
    return this.http.get<any[]>(this.baseURL  + `pending?status=${status}&technician=`+id)
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }


   getResolvedbyPagination(){
    return this.http.get<any[]>(this.baseURL + 'resolve/' + 50 + "/" + 0 )
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getComplaintsPagination(){
    return this.http.get<any[]>(this.baseURL + 'complaint/' + 50 + "/" + 0 )
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }
}
