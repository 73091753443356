import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ProcessHTTPMsgService } from './process-httpmsg.service';
import { Router } from '@angular/router';

interface AuthResponse {
  status: string;
  success: string;
  token: string;
}

interface JWTResponse {
  status: string;
  success: string;
  user: any;
}


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  tokenKey = 'JWT';
  isAuthenticated: Boolean = false;
  username= new BehaviorSubject(null);
  authToken: string = undefined;
  isAdmin:boolean = true;
  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHTTPMsgService,
    @Inject('baseURL') private baseURL,
    private router:Router
  ) {}

  checkJWTtoken() {
    this.http.get<JWTResponse>(this.baseURL + 'users/checkJWTtoken').subscribe(
      (res) => {

        if(res.user.role==="admincci"){
          this.isAdmin =  true
        }else{
          this.isAdmin =  false
          this. logOut()
        }
      
        this.sendUsername(res.user);
      },
      (err) => {

        this.destroyUserCredentials();
      }
    );
  }

  sendUsername(name) {    
    this.username.next(name);
  }

  clearUsername() {
    this.username.next(undefined);
  }

  loadUserCredentials() {
    const credentials = JSON.parse(localStorage.getItem(this.tokenKey));
    if (credentials && credentials.username !== undefined) {
      this.useCredentials(credentials);
      if (this.authToken) {
        this.checkJWTtoken();
      }
    }
  }

  storeUserCredentials(credentials: any) {
    localStorage.setItem(this.tokenKey, JSON.stringify(credentials));
    this.useCredentials(credentials);
    this.loadUserCredentials()
  }

  useCredentials(credentials: any) {
    this.isAuthenticated = true;
    //  this.sendUsername(credentials.username);
    this.authToken = credentials.token;
  }

  destroyUserCredentials() {
    this.authToken = undefined;
    this.clearUsername();
    this.isAuthenticated = false;
    localStorage.removeItem(this.tokenKey);
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      methode: 'POST',
    }),
  };
  logIn(user: any): any {
    this.http
      .post<AuthResponse>(
        this.baseURL + 'users/login',
        { username: user.username, password: user.password },
        this.httpOptions
      )
      .subscribe(
        (res) => {
          this.storeUserCredentials({
            username: user.username,
            token: res.token,
          });

          //  this.router.navigate(['/dashboard']);
         
           document.getElementById('exampleModalPopup').click()
        },
        (error) => {
        alert(error.error.err.message)
        }
      );
  }
  signUp(user: any): any {
  return  this.http
      .post(this.baseURL + 'users/signup', user, this.httpOptions);
     
  }

  saveSideUser(user){
    this.http.post(this.baseURL + 'sideusers', user)
    .pipe(catchError(this.processHTTPMsgService.handleError)).subscribe(res=>{
      this.router.navigate(["users"]);
    })
  }


  logOut() {
    this.destroyUserCredentials();
  }

  isLoggedIn(): Boolean {
    return this.isAuthenticated;
  }

  isAdminUser(){
    if (this.authToken) {
      return  this.isAdmin;
    }
    else{
      return false;
    }
  }

  getUserDetails(){
    return this.username.asObservable();
  }

  getToken(): string {
    return this.authToken;
  }



  
}
