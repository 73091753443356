<div class="head_top">


    <section class="banner_main p-3 mt-5">
        <div class="container-fluid w-95 mt-5">
            <div class="row d_flex">
                <div class="col-md-6">
                    <div class="text-bg">
                        <h1>CCI Portal</h1>
                        <p>We invest in our talent, which enables us to deliver exceptional results, build a highly capable and diverse organization, and remain a great place to work.</p>

                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text-img">
                        <figure><img src="../../assets/images/logo.png" /></figure>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>