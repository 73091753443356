<nav class="navbar navbar-light p-0">
    <div class="container-fluid w-95">


        <ul class="navbar-nav me-auto">
            <h3 *ngIf="!appUser">CCI Portal</h3>

            <button class="btn btn-default menu-btn" id="menu-trigger" data-bs-toggle="offcanvas" *ngIf="appUser" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions"></button>

        </ul>

        <ul class="navbar-nav ml-auto d-flex">


            <li class="nav-item" *ngIf="!appUser">
                <a class="nav-link btn" data-bs-toggle="modal" data-bs-target="#exampleModalPopup">
                        Login
                    </a>

            </li>
            <li class="nav-item" *ngIf="appUser">
                <a class="nav-link user dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="../../assets/images/user.jpg" alt="image" class="rounded-circle cursor-pointer">
                </a>
                <ul class="dropdown-menu">
                    <div class="welcome">
                        <div class="name">
                            <div class="circle">
                                {{ appUser.username | uppercase | shortName}}
                            </div>
                            <span id="username">{{ appUser.username }}</span>
                            <span class="mb-1">{{ appUser.email }}</span>
                        </div>
                    </div>

                    <li><a class="dropdown-item" (click)="logout()">Logout</a></li>


                </ul>
            </li>

        </ul>

    </div>
</nav>

<div class="offcanvas offcanvas-start" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
    <div class="offcanvas-header" *ngIf="appUser">
        <h5>CCI Portal</h5>
        <p class="offcanvas-title" id="offcanvasWithBothOptionsLabel">{{ appUser.username }}</p>

        <div class="mb-1 d-block">{{ appUser.email }}</div>
        <button type="button" class="btnClose text-reset" data-bs-dismiss="offcanvas" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <ng-container *ngIf="appUser">
        <!--  -->
        <div class="offcanvas-body" *ngIf="appUser.role === 'admincci'">
            <ul class="p-0">
                <li routerLinkActive="is-active" routerLink="dashboard"><i class="bi bi-house-door"></i>Dashboard</li>
                <li routerLinkActive="is-active" routerLink="upload/excel"><i class="bi bi-file-earmark-spreadsheet"></i>Upload Excel</li>
                <!-- <li routerLinkActive="is-active" routerLink="upload/excel">Upload Excel</li> -->
                <li class="dropdown-btn" data-bs-toggle="collapse" href="#upload"> <i class="bi bi-upload"></i>Upload History
                    <i class="bi bi-chevron-left over-triangle"></i>
                </li>
                <div class="collapse dropdown-container" id="upload">
                     <li routerLinkActive="is-active" routerLink="uploaded/complaints/today">  <i class="bi bi-chevron-double-right"></i> Recent Complaints</li>
                    <li routerLinkActive="is-active" routerLink="uploaded/complaints/all">  <i class="bi bi-chevron-double-right"></i> All Complaints</li>
                </div>
                <li class="dropdown-btn"  data-bs-toggle="collapse" href="#pending" > <i class="bi bi-envelope-plus"></i>Pending
                    <i class="bi bi-chevron-left over-triangle"></i>
                </li>
                <div class="collapse dropdown-container" id="pending">
                     <li routerLinkActive="is-active" routerLink="complaints/pending/received">  <i class="bi bi-chevron-double-right"></i> Received</li>
                      <li routerLinkActive="is-active" routerLink="complaints/pending/not-received"> <i class="bi bi-chevron-double-right"></i> Not-Received</li>
                     <li routerLinkActive="is-active" routerLink="complaints/pending/unmatched"> <i class="bi bi-chevron-double-right"></i> Unmatched</li>
                </div>
                <li class="dropdown-btn"  data-bs-toggle="collapse" href="#resolved"> <i class="bi bi-envelope-check"></i>Resolved
                    <i class="bi bi-chevron-left over-triangle"></i>
                </li>
                <div class="collapse dropdown-container" id="resolved">
                      <li routerLinkActive="is-active" routerLink="complaints/resolved/mc"> <i class="bi bi-chevron-double-right"></i> MC</li>
                      <li routerLinkActive="is-active" routerLink="complaints/resolved/today"> <i class="bi bi-chevron-double-right"></i> Resolved</li>
                      <li routerLinkActive="is-active" routerLink="complaints/resolved/all"> <i class="bi bi-chevron-double-right"></i> Master Data</li>
                </div>
                <li class="dropdown-btn" data-bs-toggle="collapse" href="#account"><i class="bi bi-person-plus"></i>Accounts
                    <i class="bi bi-chevron-left over-triangle"></i>
                </li>
                <div class="collapse dropdown-container" id="account">
                       <li routerLinkActive="is-active" routerLink="users/accounts"> <i class="bi bi-chevron-double-right"></i> Accounts</li>
                       <li routerLinkActive="is-active" routerLink="user/signup"> <i class="bi bi-chevron-double-right"></i> Create Account</li>
                </div>
                <!-- <li routerLinkActive="is-active" routerLink="complaints/all/filter">Filter</li> -->
                <li routerLinkActive="is-active" routerLink="complaints/export"><i class="bi bi-download"></i>Export</li>
            </ul>

        </div>
    </ng-container>
</div>

<div class="modal fade" id="exampleModalPopup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <div class="text-center w-100">
                    <h5 class="modal-title" id="exampleModalLabel">Account Login</h5>
                </div>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="LoginForm">
                    <div class="form-group">
                        <input type="text" formControlName="username" name="username" placeholder="Username" class="form-control">
                    </div>
                    <div class="form-group mt-2">
                        <input type="password" formControlName="password" name="password" placeholder="Password" class="form-control">
                    </div>
                    <div class="text-center w-100">
                        <button class="btn blu mt-2" (click)="login()" type="submit" id="one">
                        Login
                    </button>
                    </div>

                </form>
            </div>

        </div>
    </div>
</div>