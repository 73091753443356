<!-- Button to Open the Modal -->

<div class="blu btn d-none" data-bs-toggle="modal" id="error" data-bs-target="#errorModal"></div>

<div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <div class="text-center w-100">
                    <h5 class="modal-title" id="errorModalLabel">An error is occured these orders number are not upload.</h5>
                </div>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <b *ngFor="let item of notDupli; let i = index"> {{ item.order_no }}, </b>
            </div>

        </div>
    </div>
</div>

<div class="main">
    <form class="main-form" [formGroup]="firstFormGroup">
        <mat-radio-group class="gen-change-type">
            <div class="gen-change-type__main">
                <div class="gen-change-type__excel">
                    <mat-radio-button (click)="tMatRadioExcel.click()" value="2" checked>Excel</mat-radio-button>
                    <label>
            <i class="temp-upload-icon" for="excel-upload">Click To Upload</i>
            <input
            #tMatRadioExcel
              type="file"
              id="excel-upload"
              accept=".xlsx"
              multiple="false"
              (change)="inputExcelOnClick($event)"
            />
          </label>
                </div>
                <div *ngIf="notDupli.length>0">
                    <b style="color: red;"> An error is occured these orders number are not upload.
                    <span *ngFor="let item of notDupli; let i = index" style="color: black;"> {{ item }},  </span>
                   
                </b>
                </div>
            </div>

        </mat-radio-group>
        <div class="gen-drop">
            <div class="dropzone" *ngIf="isEmptyDrop" appDragDrop (filesChangeEmiter)="dropExcelOnChance($event)" (filesInvalidEmiter)="dropExcelBlock($event)" [allowed_extensions]="[
          'xlsx',
          'xls',
          'xlsm',
          'xltx',
          'xltm',
          'xlsb',
          'xlam'
        ]">
                <div class="text-wrapper">
                    <!-- <div class="centered">DRAG & DROP</div>
          <div class="centered-sub">Excel format is suggested.</div> -->
                </div>
            </div>
            <div class="display-sheet-js" [hidden]="!(!isEmptyDrop && isExcelDrop)">
                <div class="example-container">
                    <table cdk-table [dataSource]="excelDataEncodeOld">
                        <tr cdk-header-row *cdkHeaderRowDef="excelTransformNum | slice: 1; sticky: true"></tr>
                        <tr cdk-row *cdkRowDef="let row; columns: excelTransformNum | slice: 1"></tr>
                        <!-- <ng-container cdkColumnDef="order" sticky>
                <th cdk-header-cell *cdkHeaderCellDef> {{sheetMaxRow }} </th>
                <td cdk-cell *cdkCellDef="let element  ; let idx = index"> {{idx}} </td>
              </ng-container> -->
                        <ng-container *ngFor="
                let disCol of excelTransformNum | slice: 1;
                let colIndex = index
              " cdkColumnDef="{{ disCol }}">
                            <th cdk-header-cell *cdkHeaderCellDef>{{ validate[disCol] }}</th>
                            <td cdk-cell *cdkCellDef="let element">{{ element[disCol] }}</td>
                        </ng-container>
                    </table>
                </div>
            </div>
            <!-- <div class="display-pdf" [hidden]="!(!isEmptyDrop && !isExcelDrop)">
          <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="true">
          </pdf-viewer>
      </div> -->
        </div>
        <div class="gen-btn">
            <!-- <button class="mat-custom-btn" mat-raised-button>Confirm</button> -->
            <button mat-raised-button color="primary" [disabled]="!dupli" (click)="Send()">
        Send Complaints
      </button>
            <!-- <button id="model" *ngIf="!dupli" type="button" mat-raised-button color="primary" data-toggle="modal" data-target="#myModal">
        View Duplicate
      </button> -->
        </div>
    </form>
</div>
